<template>
  <main>
    <a-page-header
      style="border: 1px solid rgb(235, 237, 240)"
      title="Назад"
      @back="() => $router.go(-1)"
    />

    <a-form-model style="padding: 20px" layout="vertical" :model="lpu">
      <a-form-model-item label="Статус">
        <a-radio-group v-model="lpu.status" @change="onRadioChange">
          <a-radio :value="2">
            Черновик (не будет отображаться в приложении)
          </a-radio>
          <a-radio :value="0"> Опубликовано </a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="Название">
        <a-input v-model="lpu.name" placeholder="Название ЛПУ" />
      </a-form-model-item>
      <a-form-model-item label="Адрес">
        <a-input
          v-model="lpu.address"
          placeholder="Краснодарский край, г. Краснодар, ул. Постовая 114"
        />
      </a-form-model-item>
      <a-form-model-item label="Телефоны">
        <a-input v-model="lpu.phones" placeholder="79181234567 79189837557" />
      </a-form-model-item>
      <a-form-model-item label="Расписание">
        <a-input v-model="lpu.schedule" placeholder="Пн-Пт 0700-2200" />
      </a-form-model-item>
      <a-form-model-item label="Latitude">
        <a-input v-model="lpu.latitude" placeholder="54.193122" />
      </a-form-model-item>
      <a-form-model-item label="Longitude">
        <a-input v-model="lpu.longitude" placeholder="54.193122" />
      </a-form-model-item>
      <a-form-model-item label="email">
        <a-input v-model="lpu.email" placeholder="email@yandex.ru" />
      </a-form-model-item>
      <a-form-model-item label="url">
        <a-input v-model="lpu.url" placeholder="checkskin.ru" />
      </a-form-model-item>
      <a-checkbox
        style="margin-bottom: 1rem"
        @change="onChange"
        v-model="lpu.htmc_available"
      >
        Доступная высокотехнологичная медицинская помощь (ВМП)
      </a-checkbox>

      <a-form-model-item>
        <a-button type="primary" @click="saveLpu"> Сохранить ЛПУ </a-button>
      </a-form-model-item>
    </a-form-model>
  </main>
</template>

<script>
import axios from "axios";

export default {
  props: ["lpuId"],
  data: function () {
    return {
      lpu: {
        name: "",
        address: "",
        phones: "",
        schedule: "",
        latitude: "",
        longitude: "",
        htmc_available: "",
        email: "",
        url: "",
        status: "",
      },
      lpu_name: null,
      lpu_id: this.lpuId,
      errorMessage: "Осталось заполнить поле «Заголовок»",
      userInputTags: "",
    };
  },
  created() {
    this.getLpu();
  },
  methods: {
    onRadioChange(e) {
      this.lpu.status = e.target.value;
    },
    onChange(e) {
      // console.log(`checked = ${e.target.checked}`);
      this.lpu.htmc_available = e.target.checked;
      console.log(this.lpu.htmc_available);
    },
    getLpu: function () {
      axios
        .get(`/cp/health-facilities/${this.lpuId}/`)
        .then((response) => {
          this.lpu.name = response.data.data.name;
          this.lpu.address = response.data.data.address;
          this.lpu.phones = response.data.data.phones;
          this.lpu.latitude = response.data.data.lat;
          this.lpu.longitude = response.data.data.lon;
          this.lpu.htmc_available = response.data.data.htmc_available;
          this.lpu.email = response.data.data.email;
          this.lpu.schedule = response.data.data.schedule;
          this.lpu.url = response.data.data.url;
          this.lpu.status = response.data.data.status;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    saveLpu: function () {
      axios
        .patch(`/cp/health-facilities/${this.lpuId}/`, {
          name: this.lpu.name,
          address: this.lpu.address,
          phones: this.lpu.phones,
          schedule: this.lpu.schedule,
          lat: this.lpu.latitude,
          lon: this.lpu.longitude,
          htmc_available: this.lpu.htmc_available,
          email: this.lpu_email,
          url: this.lpu_url,
          status: this.lpu.status,
        })
        .then((response) => {
          console.log(response);
          this.$router.go(-1);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deleteLpu: function () {
      console.log("deleteLpu clicked..");
    },
  },
};
</script>
